import React from "react";
import InfoCard from "../components/common/InfoCard";
import ServiceCard from "../components/Services/ServiceCard";

import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer/Footer";

const DomainHosting = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="w-full overflow-hidden">
        <InfoCard
          mirrored={false}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover bg-center h-[400px] sm:h-[500px] w-full  bg-noWorries rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Worry-Free Hosting"
              description="Entrust your hosting needs to our experts. We handle all the technical aspects, ensuring your project runs smoothly and efficiently. This allows you to concentrate on your core business priorities without any technical distractions."
            ></ServiceCard>
          }
        ></InfoCard>
        <InfoCard
          mirrored={true}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover bg-center h-[400px] sm:h-[500px] w-full bg-noOverpay rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-right text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Cost-Effective Solutions"
              description="Our hosting services prioritize efficiency and affordability. We deliver top-quality solutions that maximize value without unnecessary expenses, ensuring you receive excellent service without overpaying."
            ></ServiceCard>
          }
        ></InfoCard>
      </div>
      <Footer />
    </>
  );
};

export default DomainHosting;
