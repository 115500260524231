import React, { useRef } from "react";
import Clients from "../components/Clients/Clients";
import Cta from "../components/ContactBanner/ContactBanner";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import Intro from "../components/Intro/Intro";
import Services from "../components/Services/Services";
import NavBar from "../components/Navbar/NavBar";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <NavBar />
      <Hero />
      <div id="about">
        <Intro />
      </div>
      <div id="services">
        <Services />
      </div>
      <Clients />
      <Cta />
      <Footer />
    </div>
  );
};

export default Home;
