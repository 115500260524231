import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer/Footer";
import { useDocTitle } from "../components/common/CustomHook";
import EmailForm from "../components/Email/EmailForm";
import InfoCard from "../components/common/InfoCard";

const Contact = () => {
  useDocTitle("E-mage | Send us a message");
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id="contact">
        <InfoCard
          mainCard={<EmailForm></EmailForm>}
          extraCardHeight="-mt-10 lg:-mt-20 xl:-mt-80"
          extraCard={
            <>
              <div className="flex my-4 w-2/3 lg:w-3/4 mt-">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl">Office address</h2>
                  <p className="text-gray-400">
                    Brigade Pironlaan 5A<br></br> 8400 Oostende<br></br> Belgium
                  </p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>

                <div className="flex flex-col">
                  <h2 className="text-2xl">Call us</h2>
                  <p className="text-gray-400">Tel: +32 486 11 62 04</p>

                  <div className="mt-5">
                    <h2 className="text-2xl">Send an e-mail</h2>
                    <p className="text-gray-400">hamlet@e-mage.be</p>
                  </div>
                </div>
              </div>

              {/* <div className="flex my-4 w-2/3 lg:w-1/2">
                <a
                  href="https://www.linkedin.com/company/e-magecommv"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-current font-black hover:animate-pulse"
                  >
                    <circle cx="4.983" cy="5.009" r="2.188"></circle>
                    <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                  </svg>
                </a>
                <a
                  href="https://www.facebook.com/emagecommv"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="fill-current font-black hover:animate-pulse"
                  >
                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                  </svg>
                </a>
              </div> */}
            </>
          }
        />
      </div>
      <Footer />
    </>
  );
};

export default Contact;
