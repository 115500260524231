import React from "react";
import InfoCard from "../components/common/InfoCard";
import ServiceCard from "../components/Services/ServiceCard";

import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer/Footer";

const WebDevelopment = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="w-full overflow-hidden">
        <InfoCard
          mirrored={false}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover bg-center h-[400px] sm:h-[500px] w-full bg-frontend rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Front-end development"
              description="We create intuitive, responsive, and visually appealing user interfaces that engage and delight users, ensuring seamless interaction across all devices. Our designs are tailored to provide exceptional user experiences and make your digital presence stand out."
            ></ServiceCard>
          }
        ></InfoCard>
        <InfoCard
          mirrored={true}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover h-[400px] sm:h-[500px] w-full bg-backend rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-right text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Backend development"
              description="From robust database management to efficient server optimization, we ensure your digital infrastructure operates smoothly and securely, providing a solid foundation for your platform to thrive."
            ></ServiceCard>
          }
        ></InfoCard>
        <InfoCard
          mirrored={false}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover bg-center h-[400px] sm:h-[500px] w-full bg-fullstack rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Full stack development"
              description="Our full stack development services cover both front-end and back-end aspects of your web application, ensuring seamless integration and optimized performance for a cohesive and efficient digital experience."
            ></ServiceCard>
          }
        ></InfoCard>
        <InfoCard
          mirrored={true}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover bg-center h-[400px] sm:h-[500px] w-full bg-uiUx rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-right text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="UI/UX"
              description="At e-mage, we prioritize accessibility and user experience. We create interfaces that are visually stunning, load quickly, and are usable by everyone. Our focus on user-centered design ensures an effortless and enjoyable journey for your users."
            ></ServiceCard>
          }
        ></InfoCard>
      </div>
      <Footer />
    </>
  );
};

export default WebDevelopment;
