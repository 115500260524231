import clsx from "clsx";
import React, { ReactNode } from "react";

interface InfoCardProps {
  mainCard: ReactNode;
  extraCard: ReactNode;
  mirrored?: boolean;
  extraCardHeight?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({
  mainCard,
  extraCard,
  mirrored = false,
  extraCardHeight = "-mt-10 sm:-mt-20 xl:-mt-64",
}) => {
  const extraCardClassName = clsx(
    "w-full sm:w-4/6 md:w-3/6 xl:w-2/6 px-8 py-6 bg-blue-900 rounded-2xl",
    extraCardHeight
  );
  return (
    <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
      <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
        {mirrored ? (
          <>
            <div className="w-full flex justify-end">{mainCard}</div>
            <div className="w-full flex justify-center sm:justify-start">
              <div className={extraCardClassName}>
                <div className="flex flex-col text-white">{extraCard}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex justify-start">{mainCard}</div>
            <div className="w-full flex justify-center sm:justify-end">
              <div className={extraCardClassName}>
                <div className="flex flex-col text-white">{extraCard}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoCard;
