import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import SoftwareDevelopment from "./pages/SoftwareDevelopment";
import WebDevelopment from "./pages/WebDevelopment";
import DomainHosting from "./pages/DomainHosting";

import { useDocTitle } from "./components/common/CustomHook";
import ScrollToTop from "./components/common/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Toast from "@radix-ui/react-toast";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 500,
      easing: "ease-out-cubic",
    });

    const handleResize = () => {
      AOS.refresh();
    };

    window.addEventListener("resize", handleResize);
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useDocTitle("E-mage | Bespoke tech solutions");

  return (
    <Toast.Provider>
      <Router>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/softwaredevelopment"
            element={<SoftwareDevelopment />}
          />
          <Route path="/domainHosting" element={<DomainHosting />} />
          <Route path="/webdevelopment" element={<WebDevelopment />} />
        </Routes>
      </Router>
    </Toast.Provider>
  );
}

export default App;
