import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.setTimeout(() => {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }, 0);
  };

  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#about"
        scroll={(el) => scrollWithOffset(el)}
      >
        About
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#services"
        scroll={(el) => scrollWithOffset(el)}
      >
        Services
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/contact#contact"
        scroll={(el) => scrollWithOffset(el)}
      >
        Contact
      </HashLink>
    </>
  );
};

export default NavLinks;
