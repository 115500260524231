import React from "react";
import InfoCard from "../components/common/InfoCard";
import ServiceCard from "../components/Services/ServiceCard";

import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer/Footer";

const SoftwareDevelopment = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="w-full overflow-hidden">
        <InfoCard
          mirrored={false}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover h-[400px] sm:h-[500px] w-full bg-appDevelopment rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="App Development"
              description="We transform your ideas into modern, high-quality, and user-friendly applications tailored to your unique business needs. Whether you require mobile or desktop solutions, e-mage delivers robust, scalable, and innovative apps that enhance user engagement and drive your business forward."
            ></ServiceCard>
          }
        ></InfoCard>
        <InfoCard
          mirrored={true}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover bg-center h-[400px] sm:h-[500px] w-full bg-embeddedSystems rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-right text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Embedded Systems"
              description="We specialize in designing firmware and software to optimize the performance of microcontrollers and IoT devices, tailored to meet your specific requirements. Our solutions ensure efficient, reliable, and secure operation, enabling seamless integration and enhanced functionality for your embedded systems."
            ></ServiceCard>
          }
        ></InfoCard>
        <InfoCard
          mirrored={false}
          mainCard={
            <div className="w-full sm:w-9/12">
              <div className="bg-cover h-[400px] sm:h-[500px] w-full bg-automations rounded-2xl shadow-2xl"></div>
              <div className="mx-2 text-xs text-gray-400 text-opacity-35">
                Designed by <a href="https://www.freepik.com">Freepik</a>
              </div>
            </div>
          }
          extraCard={
            <ServiceCard
              title="Automations"
              description="Save time and money by empowering your business with streamlined processes to optimize workflows and boost productivity. Our expertise simplifies complexities and automates repetitive tasks, enhancing operational efficiency across data processing, workflow management, and beyond."
            ></ServiceCard>
          }
        ></InfoCard>
      </div>
      <Footer />
    </>
  );
};

export default SoftwareDevelopment;
